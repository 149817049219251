/* signup.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-color: #dcdcdc;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-form input {
    margin-bottom: 10px;
    width: 200px;
    padding: 10px;
  }
  
  .signup-form button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
  }
  
  .signup-form button:hover {
    background-color: #0056b3;
  }
  
  .signup-form .sign-up-button {
    width: 200px;
    margin: 10px;
  }
  
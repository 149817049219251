/* styles.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #dcdcdc;
}

h1 {
font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 50px;
line-height: 80px;
letter-spacing: -0.015em;
color: #2e2e2e;
}
@media screen and (max-width:700px){
  h1 {
    font-size: 10vw;
    line-height: 12vw;
    text-align: center;
  }
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 1.5;
}

form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}

form label {
  margin-bottom: 10px;
}

form input[type="email"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form input[type="submit"] {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
}

form input[type="submit"]:hover {
  background-color: #0056b3;
}

button {
  top:57%;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  border:none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s, transform 0.3s;
  padding: 10px 20px;
  background-color: #18A0FB;
  color: white;
  font-size: larger;
  text-align: center;
  line-height: inherit;
  margin-top: 20px;
}

button:hover {
  background-color: #478cd5;
}

button:active {
  background-color: #1d73d0;
  transform: translateY(2px);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  
  
}

/*login css*/

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-color: #dcdcdc;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form input {
    margin-bottom: 10px;
    width: 200px;
    padding: 10px;
}

button.login-button {
    width: 200px;
    padding: 10px;
    margin-top: 10px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

button.login-button:hover {
    background-color: #0056b3;
}

button.login-button {
    width: 200px !important;
    margin: 10px auto !important;
}


.python-lesson {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.coding-environment {
  display: flex;
  flex-direction: column;
}

.python-block {
  margin-bottom: 20px;
}

.editor {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px; /* Add padding-bottom to create space for the button */
}

.output {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.output h2 {
  margin-top: 0;
  font-size: 1.2rem;
  color: #333;
}

.output pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
}

.python-block .action-buttons {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Add margin-top for spacing */
}

.python-block .action-buttons button {
  padding: 8px 16px;
  background-color: #18A0FB;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

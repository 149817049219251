/* Global Styles */
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-text {
  color: #333;
  margin-left: 15px;
}

.logo-link {
  text-decoration: none;
}

.logo {
  width: 70px;
  height: 70px;
}
@media screen and (max-width: 400px) {
  .logo {
    width: 50px;
    height: 50px;
  }

}

.nav {
  display: flex;
}

.top-links,
.signup-btn {
  margin-left: 20px;
  color: #333;
  text-decoration: none;
  font-size: clamp(0.8rem, 2vw, 1.5rem);
}
@media screen and (max-width: 450px) {
  .top-links,
  .signup-btn {
  margin-left: 5px;
  }
}

.signup-btn {
  padding: 10px 20px;
  border: 2px solid #333;
  border-radius: 5px;
}

.top-links,
.signup-btn {
  transition: color 0.3s ease, font-size 0.3s ease;
}

.top-links:hover,
.signup-btn:hover {
  color: #18A0FB;
  font-size: clamp(0.88rem, 2.2vw, 1.32rem);
}

.logo-link:hover .logo-text {
  transition: color 0.3s ease, font-size 0.3s ease, margin-left 0.3s ease;
  color: #18A0FB;
  font-size: clamp(1.32rem, 2.2vw, 1.5rem);
  margin-left: 5px;
}

/* Hero */

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 0;
}

@media screen and (max-width: 700px) {
  .hero {
    flex-direction: column;
    justify-content: center;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-title {
  padding: 0 2vw;
}

.hero-button-container button{
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #18A0FB;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.main-photo {
  flex: 1;
  max-width: 50%;
  object-fit: contain;
}
@media screen and (max-width: 400px)
{
  .main-photo
  {
    width: 60%;
    max-width: 60%;
    height: auto;
  }
}

/* Body1 */
.body1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 calc(33.33% - 20px);
  margin: 10px;
  text-align: center;
}

.group img {
  width: 50%;
}

.group h2,
.group p {
  font-size: clamp(0.8rem, 2vw, 1.2rem);
}

.section-title {
  margin-left: 2vw;
}

/* Body2 */
.body2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.step.reverse {
  flex-direction: row-reverse;
}

.text-group,
.image {
  flex-basis: 50%;
  padding: 0 20px;
  box-sizing: border-box;
}

.text-group {
  order: 1;
}

.image {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-img {
  width: 30%;
  height: auto;
}

.image-img2 {
  width: 50%;
  height: auto;
}

/* SignUp Button */
.submit-message {
  margin-top: 5%;
  margin-bottom: 5%;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.signup-text {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup {
  display: grid;
  justify-content: center;
  align-items: center;
}

.email-input {
  padding: 10px;
  width: 350px;
  border: 2px solid #333;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 1rem;
}

.submit-button {
  padding: 10px 20px;
  background-color: #18A0FB;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

/* Footer */
.footer {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  flex-wrap: wrap;
}

.line {
  width: 100%;
  border-top: 1px solid #000000;
  margin-bottom: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo-text {
  color: #333;
  margin-left: 15px;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
}

.text-group-foot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-group-foot,
.follow-us {
  flex: 1;
  padding: 0 20px;
}

.foot-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: clamp(1rem, 2vw, 1.5rem);
  padding-left: 2vw;
}

.foot-content {
  margin-bottom: 5px;
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  white-space: nowrap;
  padding-left: 2vw;
}

.foot-text {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.follow-us {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.twitter {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  margin-left: 4vw;
}

/* coming soon */

.coming-soon {
  width: 100%;
  display: grid;
  place-items: center;
}

.door {
  width: 70%;
  overflow: hidden;
}

.soon-text {
  width: 50%;
  text-align: center;
}

/* About Page */
.about {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.team {
  display: flex;
  justify-content: space-evenly;
}

.team-member {
  display: grid;
  width: 40%;
}

.team-photo {
  width: 100%;
}

.team-text {
  text-align: center;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-photo {
  width: 20%;
}

.unity-container {
    position: relative;
    width: 80vw; /* 80% of the viewport width */
    padding-top: 45vw; /* 16:9 Aspect Ratio with 80vw width */
    overflow: hidden;
    margin: 0 auto; /* This will center the container horizontally */
}

.unity-container canvas, .unity-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.unity-parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Changed from 100vh to auto */
    padding: 5% 0; /* Add some vertical padding */
}
